.home {
    position: relative;
}

.home::after {
    content: '';
    background: #FC2D69;
    padding: 2px;
    width: 15px;
    height: 15px;
    left:35%;
    display:block;
    bottom: -10px;
    position: absolute;
    border-radius: 100px
}

.active-button {
    background: "linear-gradient(to right,#1C87E7,#00FDFE)";
    border-radius: "5px";
    cursor: "pointer";
}