.maindiv{
    background: linear-gradient(248.66deg, #172048 0%, #0B1642 100%);
    padding: 70px 0;
}
body{
    background: linear-gradient(248.66deg, #172048 0%, #0B1642 100%);
}
.maininner_div{
    width: 1264px;
    max-width: 100%;
    display: block;
    margin: auto;
}
img{
    max-width: 100%;
}
.signin_btn{
    width: 185px;
    height: 47px;
    font-size: 30px;
    font-weight: 500;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    border: 0px solid transparent;
    position: relative;
    right: 80px;
    color: #ffffff;
}
.signup_btn{
    width: 185px;
    height: 47px;
    font-size: 30px;
    font-weight: 500;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    border: 0px solid transparent;
    position: relative;
    left: 80px;
    color: #ffffff;
}
.signin_btn:before, .signup_btn:before, .mail_div:before, .pass_div:before, .uname_div:before{
    position: absolute;
    content: "";
    background-color: transparent;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    top: -3px;
    left: -14px;
    
    transform: rotate(134deg);
    z-index: 9;
}
.signin_btn:before{
    border-top: 20px solid #111B45;
}
.signin_btn:after{
    border-top: 20px solid #111B45;
}
.signup_btn:before{
    border-top: 20px solid #121C46;
}
.signup_btn:after{
    border-top: 20px solid #141D46;
}
.signin_btn:after, .signup_btn:after, .mail_div:after, .pass_div:after, .uname_div:after{
    position: absolute;
    content: "";
    background-color: transparent;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    bottom: -3px;
    right: -14px;
    transform: rotate(-46deg);
    z-index: 9;
}
.logo_section{
    position: relative;
}
.logo_border{
    position: relative;
}
.signin_logo{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 250px;
}
.google_btn{
    width: 370px;
    height: 64px;
    display: block;
    max-width: 100%;
    margin: auto;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    border: 0px solid transparent;
    font-size: 18px;
    position: relative;
}
.google_btn img{
    position: absolute;
    left: 47px;
    width: 41px;
    top: 11px;
}
.or_line{
    position: relative;
    color: white;
    font-size: 15px;
    font-weight: 500;
    margin-top: 44px;
    margin-bottom: 0px;
}
.or_line:before{
    position: absolute;
    content: '';
    height: 1px;
    background-color: #ffffff;
    width: 207px;
    left: -36px;
    top: 13px;
}
.or_line:after{
    position: absolute;
    content: '';
    height: 1px;
    background-color: #ffffff;
    width: 207px;
    right: -36px;
    top: 13px;
}
.signin_form{
    width: 370px;
    max-width: 100%;
    margin: auto;
    position: relative;
}
.signin_form:before{
    position: absolute;
    content: '';
    height: calc(100% + 80px);
    width: 3px;
    background-color: #68CFEF;
    left: -144px;
    top: -40px;
}
.email_inputs, .password_inputs, .uname_inputs{
    width: 100%;
    height: 65px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    border: 0px solid transparent;
    color: white;
    padding-left: 153px;
}
.uname_div{
    margin-top: 76px;
}
.pass_div, .mail_div{
    margin-top: 26px;
}
.email_inputs::placeholder, .password_inputs::placeholder, .uname_inputs::placeholder{
    color: white;
}
.pass_div, .mail_div, .uname_div{
    position: relative;
}
.uname_div:before{
    border-top: 20px solid #111B45;
}
.uname_div:after{
    border-top: 20px solid #141E47;
}
.pass_div:before{
    border-top: 20px solid #111B45;
}
.pass_div:after{
    border-top: 20px solid #141E47;
}
.mail_div:before{
    border-top: 20px solid #121C45;
}
.mail_div:after{
    border-top: 20px solid #151E47;
}
.submit_btn{
    width: 100%;
    height: 50px;
    background: #0069FF;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    border: 0px solid transparent;
    margin-top: 49px;
}
.input_icon{
    position: absolute;
    left: 114px;
}
.mail_div .input_icon{
    top: 27px;
}
.pass_div .input_icon{
    top: 23px;
}
.uname_div .input_icon{
    top: 23px;
}
@media only screen and (max-width: 767px){
    .signin_btn{
        right: calc(50% - 92px);
        margin-bottom: 20px;
    }
    .signup_btn{
        left: calc(50% - 92px);
    }
    .signin_form:before{ display: none; }
    .logo_div{ padding: 34px 39px 37px 34px !important; }
}
@media only screen and (min-width: 100px){
    .form_div{ padding: 34px 39px 37px 37px !important; }
}
@media only screen and (min-width: 768px){
    .logo_div{ padding: 64px 39px 37px 34px !important; }
    .form_div{ padding: 33px 39px 37px 37px !important; }
    .signin_form:before{ left: -40px; }
}
@media only screen and (min-width: 992px){
    .logo_div{ padding: 84px 89px 87px 84px !important; }
    .form_div{ padding: 83px 89px 87px 87px !important; }
    .signin_form:before{ left: -80px; }
}
@media only screen and (min-width: 1100px){
    .logo_div{ padding: 84px 89px 87px 84px !important; }
    .form_div{ padding: 103px 139px 107px 137px !important; }
    .signin_form:before{ left: -107px; }
}
@media only screen and (min-width: 1200px){
    .logo_div{ padding: 104px 139px 107px 104px !important; }
    .form_div{ padding: 103px 139px 107px 137px !important; }
    .signin_form:before{ left: -144px; }
}