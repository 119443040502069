.modal__container{
    position:fixed;
    width: 30%;
    left:35%;
    background-color: rgba(0, 135, 165, 0.3);
    color: rgb(27, 27, 27);
    color: white;
    border: 1px solid #0087A5;
    padding:2rem;
}

